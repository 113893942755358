import { useRouter } from 'next/router'
import type { TInboxTab } from 'lib/navigation'
import { qsSchema, enumParse } from 'lib/zod'

interface IUseInboxQueryResult {
  selected: string | null
  tab: TInboxTab | null
  next: string | null
}

/**
 * Returns tab, selected, and next router parameters.
 * Returns null when it's missing.
 */
export const useInboxQueryParams = (): IUseInboxQueryResult => {
  const { query: { tab, selected, next } = {} } = useRouter()

  return {
    tab: enumParse<TInboxTab>(['incoming', 'archived'], tab),
    selected: qsSchema.parse(selected),
    next: qsSchema.parse(next),
  }
}
