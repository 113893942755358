// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getBusinessRootPath, useBusinessIdPathParam } from 'lib/navigation'

/**
 * Handles /:businessId navigation.
 * Can be used to either move to the current business root
 * or to a specific business when optional businessId argument is provided.
 */
export const useBusinessRootNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open = useCallback(
    (businessId?: string) => {
      const businessIdToUse = businessId || businessIdPathParam

      return Router.push({ pathname: getBusinessRootPath(businessIdToUse) })
    },
    [businessIdPathParam],
  )

  const getPath = useCallback(
    (businessId?: string) => {
      const businessIdToUse = businessId || businessIdPathParam

      return getBusinessRootPath(businessIdToUse)
    },
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
