import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { IPurchasersQueryParams, getPurchasersPath, useBusinessIdPathParam } from 'lib/navigation'

type TOpen = (params?: IPurchasersQueryParams) => void
type TGetPath = () => string
type TUsePurchasersNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/** Handles purchasers page navigation. */
export const usePurchasersNavigation: TUsePurchasersNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    ({ page, perPage, search, withDeleted } = {}) =>
      Router.push({
        pathname: getPurchasersPath(businessIdPathParam),
        query: {
          ...(page && { page }),
          ...(search && { search }),
          ...(perPage && { per_page: perPage }),
          ...(withDeleted === true && { withDeleted: 'true' }),
        },
      }),
    [businessIdPathParam],
  )

  const getPath: TGetPath = useCallback(() => getPurchasersPath(businessIdPathParam), [businessIdPathParam])

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
