import { useGroupQueryParam } from '../useGroupQueryParam/useGroupQueryParam'

const PURCHASE_ORDERS_TABS = [
  'draft',
  'open',
  'canceled',
  'closed',
  'archived',
  'in_review',
  'approved',
  'rejected',
] as const

export type TPurchaseOrdersTab = ArrayMember<typeof PURCHASE_ORDERS_TABS>

/**
 * Returns active purchase orders tab router query parameter.
 * Returns null when it's missing.
 */
export const usePurchaseOrdersTabQueryParam = () => useGroupQueryParam({ groups: PURCHASE_ORDERS_TABS })
