import { ERouterPage } from 'lib/navigation'

export const getPathnameAsLocation = (pathname: string) => {
  if (pathname.includes(ERouterPage.clientManager)) {
    return 'client-manager' as const
  }

  if (pathname.includes(ERouterPage.dashboard)) {
    return 'dashboard' as const
  }

  if (pathname.includes(ERouterPage.inbox)) {
    return 'inbox' as const
  }

  if (pathname.includes(ERouterPage.payables)) {
    return 'bills' as const
  }

  if (pathname.includes(ERouterPage.payments)) {
    return 'payments' as const
  }

  if (pathname.includes(ERouterPage.purchaseOrders)) {
    return 'purchase-orders' as const
  }

  if (pathname.includes(ERouterPage.receipts)) {
    return 'shipping-receipts' as const
  }

  if (pathname.includes(ERouterPage.catalog)) {
    return 'catalog' as const
  }

  if (pathname.includes(ERouterPage.vendors)) {
    return 'vendors' as const
  }

  if (pathname.includes(ERouterPage.receivables)) {
    return 'invoices' as const
  }

  if (pathname.includes(ERouterPage.purchasers)) {
    return 'customers' as const
  }

  if (pathname.includes(ERouterPage.docsAndReports)) {
    return 'docs-and-reports' as const
  }

  if (pathname.includes(ERouterPage.syncStatus)) {
    return 'sync-status' as const
  }

  return null
}
