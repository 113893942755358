import { PaymentTypeEnum, ReconciliationItemTypeEnum } from 'gql/types'

export type TPaymentDialogParamValue =
  | 'direct'
  | 'amortized'
  | 'ept'
  | 'marked_as_paid'
  | 'ept_fee'
  | 'installment'
  | 'repayment'
  | 'refund'
  | 'cash-advance'

export const getPaymentDialogParamValue = (
  type: Maybe<PaymentTypeEnum | ReconciliationItemTypeEnum | 'marked_as_paid'>,
): TPaymentDialogParamValue | null => {
  switch (type) {
    case 'marked_as_paid':
      return 'marked_as_paid'
    case ReconciliationItemTypeEnum.DIRECT:
    case PaymentTypeEnum.DIRECT:
      return 'direct'
    case ReconciliationItemTypeEnum.AMORTIZED_VENDOR_PAYMENT:
    case PaymentTypeEnum.AMORTIZED_LOAN:
      return 'amortized'
    case ReconciliationItemTypeEnum.EPT_VENDOR_PAYMENT:
    case PaymentTypeEnum.EPT:
    case PaymentTypeEnum.BULLET_EPT:
      return 'ept'
    case ReconciliationItemTypeEnum.EPT_FEE:
    case PaymentTypeEnum.EPT_FEE:
      return 'ept_fee'
    case ReconciliationItemTypeEnum.AMORTIZED_INSTALLMENT:
      return 'installment'
    case ReconciliationItemTypeEnum.CASH_ADVANCE_REPAYMENT:
    case ReconciliationItemTypeEnum.EPT_REPAYMENT:
      return 'repayment'
    case ReconciliationItemTypeEnum.REFUND:
      return 'refund'
    case ReconciliationItemTypeEnum.CASH_ADVANCE:
      return 'cash-advance'
    default:
      return null
  }
}
