import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getReceivablesPath, useBusinessIdPathParam, IGetReceivablesPathParams, TReceivableGroup } from 'lib/navigation'
import type { IPaginationQueryParams } from 'lib/pagination'
import { ReceivableSortableFieldEnum, ReceivableInvoicesSortingDirectionEnum } from 'gql/types'

export interface IQueryParams extends IPaginationQueryParams {
  payerNameSearch?: string
  invoiceNumberSearch?: string
  purchaseOrderNumberSearch?: string
  confirmationNumberSearch?: string
  group?: Maybe<TReceivableGroup>
  sortingDirection?: ReceivableInvoicesSortingDirectionEnum
  sortingField?: ReceivableSortableFieldEnum
}
type TOpen = (params?: IQueryParams, options?: Parameters<(typeof Router)['push']>[2]) => Promise<boolean>
type TGetPath = (params?: Partial<IGetReceivablesPathParams>) => string
type TUseReceivableInvoicesNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/** Handles receivables page navigation. */
export const useReceivablesNavigation: TUseReceivableInvoicesNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    (
      {
        group,
        invoiceNumberSearch,
        payerNameSearch,
        purchaseOrderNumberSearch,
        confirmationNumberSearch,
        page,
        perPage,
        sortingField,
        sortingDirection,
      } = {},
      options = {},
    ) =>
      Router.push(
        {
          pathname: getReceivablesPath({ businessId: businessIdPathParam }),
          query: {
            ...(page && { page }),
            ...(group && { tab: group }),
            ...(perPage && { per_page: perPage }),
            ...(sortingField && { sort: sortingField.toLowerCase() }),
            ...(sortingDirection && { order: sortingDirection.toLowerCase() }),
            ...(payerNameSearch && { payer_name_search: payerNameSearch }),
            ...(invoiceNumberSearch && { invoice_number_search: invoiceNumberSearch }),
            ...(purchaseOrderNumberSearch && { purchase_order_number_search: purchaseOrderNumberSearch }),
            ...(confirmationNumberSearch && { confirmation_number_search: confirmationNumberSearch }),
          },
        },
        undefined,
        options,
      ),
    [businessIdPathParam],
  )

  const getPath: TGetPath = useCallback(
    ({ businessId = businessIdPathParam, ...params } = {}) => getReceivablesPath({ businessId, ...params }),
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
