import { TReceivableGroup } from 'lib/navigation/hooks'
import { getURLSearch, TQueryParams } from 'lib/navigation/helpers/getURLSearch/getURLSearch'
import { ERouterPage } from 'lib/navigation/consts'

export interface IGetReceivablesPathParams {
  businessId: Maybe<string>
  params?: TQueryParams
  group?: Maybe<TReceivableGroup>
}

/**
 * Returns path to the receivables page with optional search query param.
 */
export const getReceivablesPath = ({ businessId, params, group }: IGetReceivablesPathParams) => {
  let path = `/${businessId}${ERouterPage.receivables}`

  const searchParams = getURLSearch({ tab: group, ...params })

  if (searchParams) {
    path += `?${searchParams}`
  }

  return path
}
