import { GetServerSidePropsContext } from 'next/types'
import { initializeApollo } from 'lib/apollo-client'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

type TCheckDeactivatedAccountProps = (ctx: GetServerSidePropsContext) => Promise<boolean>

export const checkDeactivatedAccount: TCheckDeactivatedAccountProps = async (ctx) => {
  try {
    const businessId = typeof ctx.query?.businessId === 'string' ? ctx.query?.businessId : ''
    const apolloClient = initializeApollo({ token: ctx.req.cookies.token, businessId })
    const {
      data: { deactivatedBusinesses },
    } = await apolloClient.query<gql.checkDeactivatedAccount_deactivatedBusinesses>({
      query: gql.checkDeactivatedAccount_deactivatedBusinessesDocument,
      variables: { businessId },
    })

    if (deactivatedBusinesses.length) {
      return true
    }

    return false
  } catch {
    return false
  }
}
