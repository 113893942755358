import { useRouter } from 'next/router'

interface IUseClientManagerQueryParams {
  backPath: string | null
  backBusinessName: string | null
}

/**
 * Returns backPath and backBusinessName router parameters.
 * Returns null when it's missing.
 */
export const useClientManagerQueryParams = (): IUseClientManagerQueryParams => {
  const { query } = useRouter()
  const backPath = typeof query.back_path === 'string' ? query.back_path : null
  const backBusinessName = typeof query.back_business_name === 'string' ? query.back_business_name : null

  return { backPath, backBusinessName }
}
