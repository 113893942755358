import type { TPurchaseOrdersTab } from 'lib/navigation/hooks'
import { getURLSearch, TQueryParams } from 'lib/navigation/helpers/getURLSearch/getURLSearch'
import { ERouterPage } from 'lib/navigation/consts'

export interface IGetPurchaseOrdersPathParams {
  businessId: Maybe<string>
  params?: TQueryParams
  group?: Maybe<TPurchaseOrdersTab>
}

/**
 * Returns path to the purchase orders page.
 */
export const getPurchaseOrdersPath = ({ businessId, group, params }: IGetPurchaseOrdersPathParams) => {
  let path = `/${businessId}${ERouterPage.purchaseOrders}`

  const searchParams = getURLSearch({ tab: group, ...params })

  if (searchParams) {
    path += `?${searchParams}`
  }

  return path
}
