import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { useBusinessIdPathParam, getDocsAndReportsPath } from 'lib/navigation'

/**
 * Handles navigation to docs and reports page.
 */
export const useDocsAndReportsNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open = useCallback(
    () => Router.push({ pathname: getDocsAndReportsPath(businessIdPathParam) }),
    [businessIdPathParam],
  )

  const getPath = useCallback(() => getDocsAndReportsPath(businessIdPathParam), [businessIdPathParam])

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
