import { useGroupQueryParam } from '../useGroupQueryParam/useGroupQueryParam'

const PAYMENTS_GROUPS = ['scheduled', 'in_review', 'initiated', 'paid', 'failed', 'canceled'] as const

export type TPaymentsGroup = ArrayMember<typeof PAYMENTS_GROUPS>

/**
 * Returns an active payments group router parameter; returns null if it's missing.
 */
export const usePaymentsGroupQueryParam = () => useGroupQueryParam({ groups: PAYMENTS_GROUPS })
