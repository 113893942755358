import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getReceiptsPath, useBusinessIdPathParam } from 'lib/navigation'
import { IPaginationQueryParams } from 'lib/pagination'

type TOpen = (params?: IPaginationQueryParams) => void
type TGetPath = (search?: string) => string
type TUseReceiptsNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/** Handles receipts page navigation. */
export const useReceiptsNavigation: TUseReceiptsNavigation = () => {
  const businessId = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    ({ page, perPage } = {}) =>
      Router.push({
        pathname: getReceiptsPath({ businessId }),
        query: {
          ...(page && { page }),
          ...(perPage && { per_page: perPage }),
        },
      }),
    [businessId],
  )

  const getPath: TGetPath = useCallback((search?: string) => getReceiptsPath({ businessId, search }), [businessId])

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
