import { useMemo } from 'react'
import { useRouter } from 'next/router'

export const usePurchaseOrdersSearchQueryParams = () => {
  const { query } = useRouter()

  return useMemo(
    () => ({
      vendorNameSearch: String(query.vendor_name_search || ''),
      purchaseOrderNumberSearch: String(query.purchase_order_number_search || ''),
      invoiceNumberSearch: String(query.invoice_number_search || ''),
    }),
    [query.vendor_name_search, query.purchase_order_number_search, query.invoice_number_search],
  )
}
