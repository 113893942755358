// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getDashboardPath, useBusinessIdPathParam } from 'lib/navigation'
import { GUIDED_BILL_EXPERIENCE_QUERY_PARAM } from 'lib/navigation/consts'

interface IParams {
  businessId?: string
}

interface IQueryParams {
  businessId?: Maybe<string>
  scrollTo?: Maybe<string>
  invoiceId?: Maybe<string>
  guidedBillExperience?: Maybe<boolean>
}

type TOpen = (params?: IQueryParams) => Promise<boolean>

/**
 * Handles dashboard page navigation.
 * Can be used to either move to the current business root
 * or to a specific business when optional businessId argument is provided.
 */
export const useDashboardNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    (params = {}) => {
      const businessIdToUse = params.businessId || businessIdPathParam

      return Router.push({
        pathname: getDashboardPath(businessIdToUse),
        query: {
          ...(params.scrollTo && { scrollTo: params.scrollTo }),
          ...(params.invoiceId && { invoice_id: params.invoiceId }),
          ...(params.guidedBillExperience && { [GUIDED_BILL_EXPERIENCE_QUERY_PARAM]: JSON.stringify(true) }),
        },
      })
    },
    [businessIdPathParam],
  )

  const getPath = useCallback(
    ({ businessId }: IParams = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return getDashboardPath(businessIdToUse)
    },
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
