import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getClientManagerClientsPath, useBusinessIdPathParam } from 'lib/navigation'
import { IPaginationQueryParams } from 'lib/pagination'

export interface IClientManagerClientsQueryParams extends IPaginationQueryParams {
  businessId?: string
  search?: string
}

/**
 * Handles client manager clients page navigation.
 */
export const useClientManagerClientsNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open = useCallback(
    ({ businessId, page, perPage, ...params }: IClientManagerClientsQueryParams = {}, options = {}) => {
      const businessIdToUse = businessId || businessIdPathParam
      const pathname = getClientManagerClientsPath(businessIdToUse)
      const shallow = Router.asPath.includes(pathname)

      return Router.push(
        {
          pathname: getClientManagerClientsPath(businessIdToUse),
          query: {
            ...params,
            ...(page && { page }),
            ...(perPage && { per_page: perPage }),
          },
        },
        undefined,
        { shallow, ...options },
      )
    },
    [businessIdPathParam],
  )

  const getPath = useCallback(
    ({ businessId, ...params }: IClientManagerClientsQueryParams = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return getClientManagerClientsPath(businessIdToUse, params)
    },
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
