import { useRouter } from 'next/router'
import { useMemo } from 'react'

/**
 * Returns label ids router query parameter.
 * Returns null when it's missing.
 */
export const usePurchaseOrdersLabelsQueryParam = (): string[] | null => {
  const { query } = useRouter()

  return useMemo(() => {
    if (!query.label_ids) {
      return null
    }
    if (Array.isArray(query.label_ids)) {
      return query.label_ids
    }
    return [query.label_ids]
  }, [query])
}
