import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getPayablesPromoPath, useBusinessIdPathParam } from 'lib/navigation'

type TOpen = () => void
type TGetPath = () => string
type TUsePayablesPromoNavigation = () => {
  open: TOpen
  getPath: TGetPath
}

/**
 * Handles vendor payables page navigation.
 */
export const usePayablesPromoNavigation: TUsePayablesPromoNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open: TOpen = useCallback(
    () => Router.push({ pathname: getPayablesPromoPath(businessIdPathParam) }),
    [businessIdPathParam],
  )

  const getPath: TGetPath = useCallback(() => getPayablesPromoPath(businessIdPathParam), [businessIdPathParam])

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
