import { GetServerSideProps, GetStaticPropsResult } from 'next'
import { isApolloError } from '@apollo/client'
import { getLoginPath, getErrorPath } from 'lib/navigation'
import { getInactivePath } from '../getInactivePath/getInactivePath'
import { checkDeactivatedAccount } from './helpers/checkDeactivatedAccount/checkDeactivatedAccount'

export type TGetSSPContext = Parameters<GetServerSideProps>[0]
type THandleSSPErrorProps = <T>(err: Error, ctx: TGetSSPContext) => Promise<GetStaticPropsResult<T>>

/**
 * Reusable errors handler for server-side rendering in getServerSideProps.
 */
export const handleSSPError: THandleSSPErrorProps = async (err, ctx) => {
  if (isApolloError(err)) {
    const businessId = typeof ctx.query?.businessId === 'string' ? ctx.query?.businessId : ''

    if (businessId) {
      const isDeactivatedAccount = await checkDeactivatedAccount(ctx)

      if (isDeactivatedAccount) {
        return { redirect: { destination: getInactivePath(businessId), permanent: true } }
      }
    }

    const { graphQLErrors = [], networkError } = err

    for (const graphQLError of graphQLErrors) {
      return {
        redirect: {
          destination: getErrorPath({ message: graphQLError.message, reloadPath: ctx.resolvedUrl }),
          permanent: false,
        },
      }
    }

    if (networkError) {
      if ('statusCode' in networkError) {
        if (networkError.statusCode === 401) {
          return {
            redirect: {
              permanent: false,
              destination: getLoginPath({ next: ctx.resolvedUrl }),
            },
          }
        }
        if (networkError.statusCode >= 500 && networkError.statusCode <= 599) {
          return {
            redirect: {
              permanent: false,
              destination: getErrorPath({
                message:
                  'Service is unavailable at the moment. Please reload the page or try again later. If the problem persists, please contact support.',
                reloadPath: ctx.resolvedUrl,
              }),
            },
          }
        }
      }
    }
  }

  throw err
}
