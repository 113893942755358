// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { useCallback } from 'react'
import omit from 'lodash/omit'

type TRemoveRouterQueryParams = (...params: string[]) => Promise<boolean>

/**
 * Returns a function that safely cleans up router's query params.
 */
export const useRemoveRouterQueryParams = (method: 'push' | 'replace' = 'push'): TRemoveRouterQueryParams =>
  useCallback(
    (...params: string[]) => Router[method]({ query: omit(Router.query, params) }, undefined, { shallow: true }),
    [method],
  )
