import { EventEmitter2 } from 'eventemitter2'

const emitter = new EventEmitter2({})

export const emitPayablesReload = () => {
  emitter.emit('payables_reload')
}
export const onPayablesReload = (callback: () => void) => {
  emitter.on('payables_reload', callback)
}
export const offPayablesReload = (callback: () => void) => {
  emitter.off('payables_reload', callback)
}

export const emitReceivableInvoicesReload = () => {
  emitter.emit('receivable_incoices_reload')
}
export const onReceivableInvoicesReload = (callback: () => void) => {
  emitter.on('receivable_incoices_reload', callback)
}
export const offReceivableInvoicesReload = (callback: () => void) => {
  emitter.off('receivable_incoices_reload', callback)
}

export const emitReceivablesReload = () => {
  emitter.emit('receivables_reload')
}
export const onReceivablesReload = (callback: () => void) => {
  emitter.on('receivables_reload', callback)
}
export const offReceivablesReload = (callback: () => void) => {
  emitter.off('receivables_reload', callback)
}

export const emitDocsAndReportsReload = () => {
  emitter.emit('docs_and_reports_reload')
}
export const emitDashboardReload = () => {
  emitter.emit('dashboard_reload')
}
export const onDashboardReload = (callback: () => void) => {
  emitter.on('dashboard_reload', callback)
}
export const offDashboardReload = (callback: () => void) => {
  emitter.off('dashboard_reload', callback)
}

export const emitInboxReload = () => {
  emitter.emit('inbox_reload')
}
export const onInboxReload = (callback: () => void) => {
  emitter.on('inbox_reload', callback)
}
export const offInboxReload = (callback: () => void) => {
  emitter.off('inbox_reload', callback)
}

export const emitDialogPaymentApprovalsInfo = (transferId: string) => {
  emitter.emit('dialog_payment_approvals_info', transferId)
}
export const onDialogPaymentApprovalsInfo = (callback: () => void) => {
  emitter.on('dialog_payment_approvals_info', callback)
}
export const offDialogPaymentApprovalsInfo = (callback: () => void) => {
  emitter.off('dialog_payment_approvals_info', callback)
}

export type TValidateVendorAndAmountCallback = (isValidated: boolean) => void
type TValidateVendorAndAmountCallbackFunction = (callback?: TValidateVendorAndAmountCallback) => void

export const emitInvoiceDetailsValidateVendorAndAmount = (callback?: TValidateVendorAndAmountCallback) => {
  emitter.emit('invoice_details_validate_vendor_and_amount', callback)
}
export const onInvoiceDetailsValidateVendorAndAmount = (callback: TValidateVendorAndAmountCallbackFunction) => {
  emitter.on('invoice_details_validate_vendor_and_amount', callback)
}
export const offInvoiceDetailsValidateVendorAndAmount = (callback: TValidateVendorAndAmountCallbackFunction) => {
  emitter.off('invoice_details_validate_vendor_and_amount', callback)
}
export const emitAccountingSyncFinished = () => {
  emitter.emit('accounting_sync_finished')
}
export const onAccountingSyncFinished = (callback: () => void) => {
  emitter.on('accounting_sync_finished', callback)
}
export const offAccountingSyncFinished = (callback: () => void) => {
  emitter.off('accounting_sync_finished', callback)
}
export type TValidateRequestApprovalCallback = (isValidated: boolean) => void
type TValidateRequestApprovalCallbackFunction = (callback?: TValidateRequestApprovalCallback) => void
export const emitInvoiceDetailsValidateRequestApproval = (callback?: TValidateRequestApprovalCallback) => {
  emitter.emit('invoice_details_validate_request_approval', callback)
}
export const onInvoiceDetailsValidateRequestApproval = (callback: TValidateRequestApprovalCallbackFunction) => {
  emitter.on('invoice_details_validate_request_approval', callback)
}
export const offInvoiceDetailsValidateRequestApproval = (callback: TValidateRequestApprovalCallbackFunction) => {
  emitter.off('invoice_details_validate_request_approval', callback)
}

export const emitFeatureUpsell = (feature: string) => {
  emitter.emit('feature_upsell', feature)
}
export const onFeatureUpsell = (callback: (feature: string) => void) => {
  emitter.on('feature_upsell', callback)
}
export const offFeatureUpsell = (callback: (feature: string) => void) => {
  emitter.off('feature_upsell', callback)
}
