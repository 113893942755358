import { useCallback } from 'react'
import { IReceiptQueryParams } from 'lib/navigation/hooks/useReceiptNavigation/useReceiptNavigation'
import { getReceiptsPath } from 'lib/navigation/helpers/getReceiptsPath/getReceiptsPath'
import { useBusinessIdPathParam } from 'lib/navigation/hooks/useBusinessIdPathParam/useBusinessIdPathParam'

export const useOpenReceiptInNewTab = () => {
  const businessId = useBusinessIdPathParam()

  return useCallback(
    ({ receiptId }: IReceiptQueryParams) => {
      const baseUrl = window.location.origin
      const path = getReceiptsPath({ businessId, search: `receipt_id=${receiptId}` })

      window.open(new URL(path, baseUrl).toString(), '_blank')
    },
    [businessId],
  )
}
