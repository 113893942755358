import { useRouter } from 'next/router'
import { useMemo } from 'react'

interface IUsePayableQueryParamsResult {
  invoiceId: string | null
}

/**
 * Returns payable router parameters.
 * Returns null when missing.
 */
export const usePayableQueryParams = (): IUsePayableQueryParamsResult => {
  const { query } = useRouter()
  const invoiceId = typeof query.invoice_id === 'string' ? query.invoice_id : null

  return useMemo(() => ({ invoiceId }), [invoiceId])
}
