import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
// eslint-disable-next-line import/no-unresolved
import { Analytics as VercelAudiences } from '@vercel/analytics/react'
import { LicenseInfo } from '@mui/x-license'
// eslint-disable-next-line import/no-unresolved
import { SpeedInsights } from '@vercel/speed-insights/next'
import { Snackbars } from 'settle-ui/components/Snackbars/Snackbars'
import { AppProgress } from 'pages/components/AppProgress/AppProgress'
import { UserSettingsProvider } from 'pages/components/AppLayout/providers/UserSettingsProvider/UserSettingsProvider'
import { PathParams } from 'pages/components/PathParams/PathParams'
import { isTrackingDisabled } from 'lib/tracking/segment'
import { qsSchema } from 'lib/zod'
import { initDatadog } from '../../../lib/tracking/datadog'
import { setScrollbarStrategy } from './helpers/setScrollbarStrategy/setScrollbarStrategy'
import { useSetProfileIdCookie } from './hooks/useSetProfileIdCookie/useSetProfileIdCookie'
// eslint-disable-next-line
import 'lib/translations/i18next'
import { useLogSnackbarEvent } from './hooks/useLogSnackbarEvent/useLogSnackbarEvent'
import { useGlobalLDFlagsGetter } from './hooks/useGlobalLDFlagsGetter/useGlobalLDFlagsGetter'
import { AppStylesProvider, IAppStylesProviderProps } from './components/AppStylesProvider/AppStylesProvider'
import { SegmentIdentityTracking } from './components/SegmentIdentityTracking/SegmentIdentityTracking'
import { DatadogIdentityTracking } from './components/DatadogIdentityTracking/DatadogIdentityTracking'
import { GlobalStyles } from './components/GlobalStyles/GlobalStyles'
import * as events from './AppPage.events'
import { useInitializedApolloClient } from './hooks/useInitializedApolloClient/useInitializedApolloClient'

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY ?? '')

export interface IAppPageProps extends AppProps, IAppStylesProviderProps {
  pageProps: Pick<AppProps, 'pageProps'> & {
    profileId: Maybe<string>
    initialCache?: NormalizedCacheObject
  }
}

export let AppPage: React.FC<IAppPageProps> = ({ Component, pageProps, emotionCache, router }) => {
  const { profileId, initialCache } = pageProps
  const businessId = qsSchema.parse(router.query.businessId)
  const identifyUserInSegment = !isTrackingDisabled() && profileId
  const apolloClient = useInitializedApolloClient({ businessId, initialCache })

  useSetProfileIdCookie(profileId)
  useGlobalLDFlagsGetter()
  useEffect(initDatadog, [])
  useEffect(setScrollbarStrategy, [])
  const logSnackbarEvent = useLogSnackbarEvent(router)

  return (
    <>
      <Head>
        <title>Settle</title>
      </Head>
      {process.env.VERCEL_ENV === 'production' && <VercelAudiences />}
      <ApolloProvider client={apolloClient}>
        {identifyUserInSegment && <SegmentIdentityTracking profileId={profileId} />}
        {profileId && <DatadogIdentityTracking profileId={profileId} />}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RecoilRoot>
            <UserSettingsProvider>
              <AppStylesProvider emotionCache={emotionCache}>
                <GlobalStyles />
                <Snackbars
                  onMount={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_DISPLAYED)
                  }}
                  onActionClick={(snackbar) => {
                    logSnackbarEvent(snackbar, events.SNACKBAR_ACTION_BTN_CLICK)
                  }}
                />
                <AppProgress />
                <Component {...pageProps} />
                <PathParams />
              </AppStylesProvider>
            </UserSettingsProvider>
          </RecoilRoot>
        </LocalizationProvider>
      </ApolloProvider>
      {process.env.VERCEL_ENV === 'production' && <SpeedInsights />}
    </>
  )
}

if (process.env.LAUNCHDARKLY_ID) {
  // @ts-expect-error FIX
  AppPage = withLDProvider({ clientSideID: process.env.LAUNCHDARKLY_ID })(AppPage)
}
