import { useConnectedAccountingPlatform } from 'lib/hooks/useConnectedAccountingPlatform/useConnectedAccountingPlatform'
import { getAccountingPlatformName } from 'lib/helpers/getAccountingPlatformName/getAccountingPlatformName'
import { useBusiness } from 'lib/hooks/useBusiness/useBusiness'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'
import { AccountingPlatformHookFragment } from './useAccountingPlatform.graphql'
import { ProviderEnum, SyncStateEnum } from 'gql/types'

export interface IUseAccountingPlatform {
  connectedAccountingPlatform: Maybe<ProviderEnum>
  /**
   * Represents the last active platform.
   * Returns null if there is currently an active connection.
   */
  disconnectedAccountingPlatform: Maybe<ProviderEnum>
  /**
   * Represents the last active platform.
   * Includes both active and inactive connections.
   * Returns null if there was never an active connection.
   */
  lastConnectedAccountingPlatform: Maybe<ProviderEnum>
  accountingPlatformName: Maybe<string>
  syncErrorMessage: Maybe<string>
  syncState: Maybe<SyncStateEnum>
  syncInProgress: boolean
  loading: boolean
  isReadOnly: boolean
  syncAutomatically: boolean
  syncedAt: string | null
  autoImportBills: boolean | null
  state:
    | AccountingPlatformHookFragment['finaloopState']
    | AccountingPlatformHookFragment['xeroState']
    | AccountingPlatformHookFragment['netsuiteState']
    | AccountingPlatformHookFragment['quickBooksState']
}

export const useAccountingPlatform = (): IUseAccountingPlatform => {
  const businessQuery = useBusiness()
  const { business } = businessQuery
  const { data, loading } = gql.useAccountingPlatformHook({
    errorPolicy: 'all', // doesn't throw an error when user doesn't have permission to read accounting state
  })
  const { quickBooksState, xeroState, netsuiteState, accountingState, finaloopState } = data ?? {}
  const syncState =
    (business?.xeroLinked && xeroState?.syncState) ||
    (business?.netsuiteLinked && netsuiteState?.syncState) ||
    (business?.quickBooksLinked && quickBooksState?.syncState) ||
    null
  const syncErrorMessage =
    (business?.xeroLinked && xeroState?.syncErrorMessage) ||
    (business?.netsuiteLinked && netsuiteState?.syncErrorMessage) ||
    (business?.quickBooksLinked && quickBooksState?.syncErrorMessage) ||
    null
  const connectedAccountingPlatform = useConnectedAccountingPlatform()
  const disconnectedAccountingPlatform = (() => {
    if (connectedAccountingPlatform) {
      return null
    }

    return accountingState?.accountingProvider ?? null
  })()

  const autoImportBills = (() => {
    if (connectedAccountingPlatform === ProviderEnum.QUICK_BOOKS) {
      return quickBooksState?.autoImportBills ?? null
    }
    if (connectedAccountingPlatform === ProviderEnum.NETSUITE) {
      return netsuiteState?.autoImportBills ?? null
    }

    return null
  })()

  const syncInProgress = syncState === SyncStateEnum.SYNC_IN_PROGRESS || syncState === SyncStateEnum.READY_TO_SYNC
  const lastConnectedAccountingPlatform = connectedAccountingPlatform || disconnectedAccountingPlatform
  const accountingPlatformName = getAccountingPlatformName(lastConnectedAccountingPlatform)

  const isReadOnly: IUseAccountingPlatform['isReadOnly'] = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return quickBooksState?.readOnly ?? false
      case ProviderEnum.XERO:
        return xeroState?.readOnly ?? false
      case ProviderEnum.NETSUITE:
        return netsuiteState?.readOnly ?? false
      default:
        return false
    }
  })()

  const syncAutomatically = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return quickBooksState?.syncAutomatically ?? false
      case ProviderEnum.XERO:
        return xeroState?.syncAutomatically ?? false
      case ProviderEnum.NETSUITE:
        return netsuiteState?.syncAutomatically ?? false
      default:
        return false
    }
  })()

  const syncedAt: IUseAccountingPlatform['syncedAt'] = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return quickBooksState?.syncedAt ?? null
      case ProviderEnum.XERO:
        return xeroState?.syncedAt ?? null
      case ProviderEnum.NETSUITE:
        return netsuiteState?.syncedAt ?? null
      default:
        return null
    }
  })()

  return {
    connectedAccountingPlatform,
    disconnectedAccountingPlatform,
    loading: loading || businessQuery.loading,
    syncState,
    syncInProgress,
    lastConnectedAccountingPlatform,
    accountingPlatformName,
    syncErrorMessage,
    syncAutomatically,
    isReadOnly,
    syncedAt,
    autoImportBills,
    state: finaloopState || xeroState || netsuiteState || quickBooksState || null,
  }
}
