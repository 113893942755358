import { useRouter } from 'next/router'
import { useMemo } from 'react'
// eslint-disable-next-line settle/preferred-modules
import * as gql from 'gql'

const SORTING_ORDER_TO_ENUM: { [key: string]: gql.PurchaseOrderSortingDirectionEnum } = {
  asc: gql.PurchaseOrderSortingDirectionEnum.ASC,
  desc: gql.PurchaseOrderSortingDirectionEnum.DESC,
}

const getPurchaseOrdersSortingFieldEnumValue = (sortingField?: string): gql.PurchaseOrderSortableFieldEnum => {
  const sortingFieldUpperCase = sortingField?.toUpperCase() as keyof typeof gql.PurchaseOrderSortableFieldEnum

  return sortingFieldUpperCase && gql.PurchaseOrderSortableFieldEnum[sortingFieldUpperCase]
    ? gql.PurchaseOrderSortableFieldEnum[sortingFieldUpperCase]
    : gql.PurchaseOrderSortableFieldEnum.CREATED_AT
}

const getPOsSortingDirectionEnumValue = (sortingDirection?: string): gql.PurchaseOrderSortingDirectionEnum =>
  (sortingDirection && SORTING_ORDER_TO_ENUM[sortingDirection]) || gql.PurchaseOrderSortingDirectionEnum.DESC

export const usePurchaseOrdersSortingQueryParams = () => {
  const { query } = useRouter()
  const sortingField = getPurchaseOrdersSortingFieldEnumValue(String(query.sort))
  const sortingDirection = getPOsSortingDirectionEnumValue(String(query.order))

  return useMemo(() => ({ sortingField, sortingDirection }), [sortingDirection, sortingField])
}
