import { isNil, isArray, toString } from 'lodash'

export type TQueryParams = Record<string, number | string | number[] | string[] | undefined | null>

/**
 * Forms a concatenated string of query parameters.
 * @param params - Object with query parameters.
 */
export const getURLSearch = <T extends TQueryParams>(params: T): string => {
  const search = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    if (!isNil(value)) {
      search.set(key, isArray(value) ? value.map(toString).join(',') : value.toString())
    }
  }

  return search.toString()
}
