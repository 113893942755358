export interface IGetReceiptsPathParams {
  businessId: Maybe<string>
  search?: string
}

/**
 * Returns path to the receipts page.
 */
export const getReceiptsPath = ({ businessId, search }: IGetReceiptsPathParams) => {
  let path = `/${businessId}/receipts`

  if (search) {
    path += `?${search}`
  }

  return path
}
