import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { TPaymentDialogParamValue } from '../../helpers/getPaymentDialogParamValue/getPaymentDialogParamValue'

interface IUsePaymentQueryParamsResult {
  transferId: string | null
  reconciliationItemId: string | null
  paymentDialog: TPaymentDialogParamValue | null
}

/**
 * Returns payment router parameters.
 * Returns null when a param is missing.
 */
export const usePaymentQueryParams = (): IUsePaymentQueryParamsResult => {
  const { query } = useRouter()

  const reconciliationItemId = typeof query.reconciliation_item_id === 'string' ? query.reconciliation_item_id : null
  const transferId = typeof query.transfer_id === 'string' ? query.transfer_id : null
  const paymentDialog =
    typeof query.payment_dialog === 'string' ? (query.payment_dialog as TPaymentDialogParamValue) : null

  return useMemo(
    () => ({ transferId, reconciliationItemId, paymentDialog }),
    [transferId, reconciliationItemId, paymentDialog],
  )
}
