import { useCallback, useMemo } from 'react'
import { useSettingsNavigation } from '../../index'

interface IOpenParams {
  ruleId: string
}
export const useDiscrepancyRuleNavigation = () => {
  const settingsNavigation = useSettingsNavigation()
  const open = useCallback(
    ({ ruleId }: IOpenParams) => settingsNavigation.open({ tab: 'matching-edit-rule', discrepancyRuleId: ruleId }),
    [settingsNavigation],
  )

  return useMemo(() => ({ open }), [open])
}
