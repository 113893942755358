import { ButtonBase } from '@mui/material'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'
import { styledWithSelector } from 'lib/helpers/styledWithSelector/styledWithSelector'
import type { TIconButtonProps } from './IconButton.types'
import * as tkn from './IconButtonBase.tokens'
import * as tkn_p from './IconButtonPrimary.tokens'
import * as tkn_s from './IconButtonSecondary.tokens'
import * as tkn_t from './IconButtonTertiary.tokens'

const primaryNeutralActive = {
  backgroundColor: tkn_p.IconButtonPrimaryColorPositivePressedBackground,
  color: tkn_p.IconButtonPrimaryColorPositivePressedOnBackground,
  borderWidth: tkn_p.IconButtonPrimaryBorderWidthPressed,
  borderColor: tkn_p.IconButtonPrimaryColorPositivePressedOutline,
}

const secondaryNeutralActive = {
  backgroundColor: tkn_s.IconButtonSecondaryColorPositivePressedBackground,
  color: tkn_s.IconButtonSecondaryColorPositivePressedOnBackground,
  borderWidth: tkn_s.IconButtonSecondaryBorderWidthPressed,
  borderColor: tkn_s.IconButtonSecondaryColorPositivePressedOutline,
}

const tertiaryNeutralActive = {
  backgroundColor: tkn_t.IconButtonTertiaryColorPositivePressedBackground,
  color: tkn_t.IconButtonTertiaryColorPositivePressedOnBackground,
  borderWidth: tkn_t.IconButtonTertiaryBorderWidthPressed,
  borderColor: tkn_t.IconButtonTertiaryColorPositivePressedOutline,
}

const primaryDestructiveActive = {
  backgroundColor: tkn_p.IconButtonPrimaryColorDestructivePressedBackground,
  color: tkn_p.IconButtonPrimaryColorDestructivePressedOnBackground,
  borderWidth: tkn_p.IconButtonPrimaryBorderWidthPressed,
  borderColor: tkn_p.IconButtonPrimaryColorDestructivePressedOutline,
}

const secondaryDestructiveActive = {
  backgroundColor: tkn_s.IconButtonSecondaryColorDestructivePressedBackground,
  color: tkn_s.IconButtonSecondaryColorDestructivePressedOnBackground,
  borderWidth: tkn_s.IconButtonSecondaryBorderWidthPressed,
  borderColor: tkn_s.IconButtonSecondaryColorDestructivePressedOutline,
}

const tertiaryDestructiveActive = {}

type TIconButton = Pick<TIconButtonProps, 'variant' | 'kind' | 'size' | 'active'>

export const IconButton = styledWithSelector(ButtonBase, {
  shouldForwardProp: youShallNotPass('kind', 'variant', 'size', 'active'),
})<TIconButton>(({ variant, kind, size, active }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  ...(size === 'large' && {
    height: tkn.IconButtonBaseSizeLarge,
    width: tkn.IconButtonBaseSizeLarge,
    borderRadius: tkn.IconButtonBaseBorderRadiusLarge,
    paddingLeft: tkn.IconButtonBaseSpacingLargeLeft,
    paddingRight: tkn.IconButtonBaseSpacingLargeRight,
  }),
  ...(size === 'medium' && {
    height: tkn.IconButtonBaseSizeMedium,
    width: tkn.IconButtonBaseSizeMedium,
    borderRadius: tkn.IconButtonBaseBorderRadiusMedium,
    paddingLeft: tkn.IconButtonBaseSpacingMediumLeft,
    paddingRight: tkn.IconButtonBaseSpacingMediumRight,
  }),
  ...(size === 'small' && {
    height: tkn.IconButtonBaseSizeSmall,
    width: tkn.IconButtonBaseSizeSmall,
    borderRadius: tkn.IconButtonBaseBorderRadiusSmall,
    paddingLeft: tkn.IconButtonBaseSpacingSmallLeft,
    paddingRight: tkn.IconButtonBaseSpacingSmallRight,
  }),
  // TODO: to be updated once the design team creates the tiny size
  // https://linear.app/settle/issue/DES-772/add-tiny-button-variation-to-the-ds-tokens
  ...(size === 'tiny' && {
    height: '24px',
    width: '24px',
    borderRadius: '4px',
  }),
  ...(variant === 'primary' &&
    kind === 'neutral' && {
      backgroundColor: tkn_p.IconButtonPrimaryColorPositiveDefaultBackground,
      color: tkn_p.IconButtonPrimaryColorPositiveDefaultOnBackground,
      borderWidth: tkn_p.IconButtonPrimaryBorderWidthDefault,
      borderColor: tkn_p.IconButtonPrimaryColorPositiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && primaryNeutralActive),
      ...(!active && {
        '&:active': primaryNeutralActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn_p.IconButtonPrimaryColorPositiveHoveredBackground,
          color: tkn_p.IconButtonPrimaryColorPositiveHoveredOnBackground,
          borderWidth: tkn_p.IconButtonPrimaryBorderWidthHovered,
          borderColor: tkn_p.IconButtonPrimaryColorPositiveHoveredBackground,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn_p.IconButtonPrimaryColorPositiveDisabledBackground,
        color: tkn_p.IconButtonPrimaryColorPositiveDisabledOnBackground,
        borderWidth: tkn_p.IconButtonPrimaryBorderWidthDisabled,
        borderColor: tkn_p.IconButtonPrimaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'neutral' && {
      backgroundColor: tkn_s.IconButtonSecondaryColorPositiveDefaultBackground,
      color: tkn_s.IconButtonSecondaryColorPositiveDefaultOnBackground,
      borderWidth: tkn_s.IconButtonSecondaryBorderWidthDefault,
      borderColor: tkn_s.IconButtonSecondaryColorPositiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && secondaryNeutralActive),
      ...(!active && {
        '&:active': secondaryNeutralActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn_s.IconButtonSecondaryColorPositiveHoveredBackground,
          color: tkn_s.IconButtonSecondaryColorPositiveHoveredOnBackground,
          borderWidth: tkn_s.IconButtonSecondaryBorderWidthHovered,
          borderColor: tkn_s.IconButtonSecondaryColorPositiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn_s.IconButtonSecondaryColorPositiveDisabledBackground,
        color: tkn_s.IconButtonSecondaryColorPositiveDisabledOnBackground,
        borderWidth: tkn_s.IconButtonSecondaryBorderWidthDisabled,
        borderColor: tkn_s.IconButtonSecondaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'neutral' && {
      backgroundColor: tkn_t.IconButtonTertiaryColorPositiveDefaultBackground,
      color: tkn_t.IconButtonTertiaryColorPositiveDefaultOnBackground,
      borderWidth: tkn_t.IconButtonTertiaryBorderWidthDefault,
      borderColor: tkn_t.IconButtonTertiaryColorPositiveDefaultOutline,
      ...(active && tertiaryNeutralActive),
      ...(!active && {
        '&:active': tertiaryNeutralActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn_t.IconButtonTertiaryColorPositiveHoveredBackground,
          color: tkn_t.IconButtonTertiaryColorPositiveHoveredOnBackground,
          borderWidth: tkn_t.IconButtonTertiaryBorderWidthHovered,
          borderColor: tkn_t.IconButtonTertiaryColorPositiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn_t.IconButtonTertiaryColorPositiveDisabledBackground,
        color: tkn_t.IconButtonTertiaryColorPositiveDisabledOnBackground,
        borderWidth: tkn_t.IconButtonTertiaryBorderWidthDisabled,
        borderColor: tkn_t.IconButtonTertiaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'primary' &&
    kind === 'destructive' && {
      backgroundColor: tkn_p.IconButtonPrimaryColorDestructiveDefaultBackground,
      color: tkn_p.IconButtonPrimaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn_p.IconButtonPrimaryBorderWidthDefault,
      borderColor: tkn_p.IconButtonPrimaryColorDestructiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && primaryDestructiveActive),
      ...(!active && {
        '&:active': primaryDestructiveActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn_p.IconButtonPrimaryColorDestructiveHoveredBackground,
          color: tkn_p.IconButtonPrimaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn_p.IconButtonPrimaryBorderWidthHovered,
          borderColor: tkn_p.IconButtonPrimaryColorDestructiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn_p.IconButtonPrimaryColorDestructiveDisabledBackground,
        color: tkn_p.IconButtonPrimaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn_p.IconButtonPrimaryBorderWidthDisabled,
        borderColor: tkn_p.IconButtonPrimaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'destructive' && {
      backgroundColor: tkn_s.IconButtonSecondaryColorDestructiveDefaultBackground,
      color: tkn_s.IconButtonSecondaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn_s.IconButtonSecondaryBorderWidthDefault,
      borderColor: tkn_s.IconButtonSecondaryColorDestructiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && secondaryDestructiveActive),
      ...(!active && {
        '&:active': secondaryDestructiveActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn_s.IconButtonSecondaryColorDestructiveHoveredBackground,
          color: tkn_s.IconButtonSecondaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn_s.IconButtonSecondaryBorderWidthHovered,
          borderColor: tkn_s.IconButtonSecondaryColorDestructiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn_s.IconButtonSecondaryColorDestructiveDisabledBackground,
        color: tkn_s.IconButtonSecondaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn_s.IconButtonSecondaryBorderWidthDisabled,
        borderColor: tkn_s.IconButtonSecondaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'destructive' && {
      backgroundColor: tkn_t.IconButtonTertiaryColorDestructiveDefaultBackground,
      color: tkn_t.IconButtonTertiaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn_t.IconButtonTertiaryBorderWidthDefault,
      borderColor: tkn_t.IconButtonTertiaryColorDestructiveDefaultOutline,
      ...(active && tertiaryDestructiveActive),
      ...(!active && {
        '&:active': tertiaryDestructiveActive,
        '&:hover, &:focus-visible': {
          backgroundColor: tkn_t.IconButtonTertiaryColorDestructiveHoveredBackground,
          color: tkn_t.IconButtonTertiaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn_t.IconButtonTertiaryBorderWidthHovered,
          borderColor: tkn_t.IconButtonTertiaryColorDestructiveHoveredOutline,
        },
      }),
      '&:disabled': {
        backgroundColor: tkn_t.IconButtonTertiaryColorDestructiveDisabledBackground,
        color: tkn_t.IconButtonTertiaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn_t.IconButtonTertiaryBorderWidthDisabled,
        borderColor: tkn_t.IconButtonTertiaryColorDestructiveDisabledOutline,
      },
    }),
}))

export default {
  IconButton,
}
