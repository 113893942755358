import { useCallback } from 'react'
import { IPayableQueryParams } from 'lib/navigation/hooks/usePayableNavigation/usePayableNavigation'
import { getPayablesPath } from 'lib/navigation/helpers/getPayablesPath/getPayablesPath'
import { useBusinessIdPathParam } from 'lib/navigation/hooks/useBusinessIdPathParam/useBusinessIdPathParam'

export const useOpenPayableInNewTab = () => {
  const businessId = useBusinessIdPathParam()

  return useCallback(
    ({ invoiceId }: IPayableQueryParams) => {
      const baseUrl = window.location.origin
      const path = getPayablesPath({ businessId, params: { invoice_id: invoiceId } })

      window.open(new URL(path, baseUrl).toString(), '_blank')
    },
    [businessId],
  )
}
