/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonBaseSizeLarge = '48px'
export const IconButtonBaseSizeMedium = '40px'
export const IconButtonBaseSizeSmall = '32px'
export const IconButtonBaseBorderRadiusLarge = '6px'
export const IconButtonBaseBorderRadiusMedium = '5px'
export const IconButtonBaseBorderRadiusSmall = '4px'
export const IconButtonBaseSpacingSmallLeft = '4px'
export const IconButtonBaseSpacingSmallRight = '4px'
export const IconButtonBaseSpacingMediumLeft = '12px'
export const IconButtonBaseSpacingMediumRight = '12px'
export const IconButtonBaseSpacingLargeLeft = '16px'
export const IconButtonBaseSpacingLargeRight = '16px'
