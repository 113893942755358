import { throttle, isEmpty, get } from 'lodash'
import { datadogRum } from '@datadog/browser-rum'
import { getCookie } from 'cookies-next'
import { isImpersonationSession } from 'lib/helpers/isImpersonationSession/isImpersonationSession'
import { isSSR } from 'lib/helpers/isSSR/isSSR'
import { segmentServerSide } from './segmentServerSide'
import type { IUserTraits } from './types'

export const isTrackingDisabled = () => isImpersonationSession() || isSSR()

export const isClientAnalyticsUnavailable = async (): Promise<boolean> => {
  await window.analytics?.ready?.()

  // `analytics.invoked` field exists only in the initial stubbed load script
  const isAnalyticsInvoked = Boolean(get(window.analytics, 'invoked', true))
  // `analytics.initialized` field exists only in the loaded replacing CDN script
  const isAnalyticsInitialized = Boolean(get(window.analytics, 'initialized', false))

  const user = window.analytics?.user?.()
  const anonymousId = user?.anonymousId()
  const id = user?.id()

  return isAnalyticsInvoked && !isAnalyticsInitialized && !anonymousId && !id
}

const isServerSideFallbackRequired = async () => {
  const isProduction = process.env.VERCEL_ENV === 'production'

  return isProduction ? await isClientAnalyticsUnavailable() : false
}

const includingIntercom = {
  All: true,
  Intercom: true,
}

const exceptIntercom = {
  ...includingIntercom,
  // filtering for Intercom destination inside of Segment is available only on expensive plan,
  // therefore this flag lets Segment know that "track" events should not be sent to Intercom
  Intercom: false,
}

const getFacebookContext = () => {
  const fbc = getCookie('_fbc')?.toString() || null
  const fbp = getCookie('_fbp')?.toString() || null

  return { fbc, fbp }
}

const getExtraContext = () => ({
  facebook: getFacebookContext(),
})

const showEventsInConsole = (event: string, properties: Record<string, unknown> = {}) => {
  // eslint-disable-next-line no-console
  console.log(event, isEmpty(properties) ? undefined : properties)
}

export const identifyUser = async (userId: string, traits: IUserTraits) => {
  if (isTrackingDisabled()) {
    return
  }

  try {
    await window.analytics?.identify(
      userId,
      traits,
      { integrations: includingIntercom, context: getExtraContext() },
      () => window.Intercom?.('update'),
    )

    if (await isServerSideFallbackRequired()) {
      await segmentServerSide.identify({ userId, traits, integrations: includingIntercom })
      await segmentServerSide.track({
        event: "Couldn't init analytics on the client side",
        integrations: exceptIntercom,
        userId,
      })
    }
  } catch (err) {
    console.error(err)
  }
}

export const trackPage = async (name = 'Page view.', properties: Record<string, unknown> = {}) => {
  if (isTrackingDisabled()) {
    return
  }

  if (window.SHOW_EVENTS_IN_CONSOLE) {
    showEventsInConsole(name, properties)
  }

  try {
    await window.analytics?.page(name, properties, { integrations: includingIntercom, context: getExtraContext() })

    if (await isServerSideFallbackRequired()) {
      await segmentServerSide.page({ name, properties, integrations: includingIntercom })
    }
  } catch (err) {
    console.error(err)
  }
}

export const logEvent = async (event: string, properties: Record<string, unknown> = {}) => {
  datadogRum.addAction(event, properties)

  if (isTrackingDisabled()) {
    return
  }

  if (window.SHOW_EVENTS_IN_CONSOLE) {
    showEventsInConsole(event, properties)
  }

  try {
    await window.analytics?.track(event, properties, { integrations: exceptIntercom, context: getExtraContext() })

    if (await isServerSideFallbackRequired()) {
      await segmentServerSide.track({ event, properties, integrations: exceptIntercom })
    }
  } catch (err) {
    console.error(err)
  }
}

export const resetTracking = () => {
  if (isTrackingDisabled()) {
    return
  }

  window.analytics?.reset()
  window.Intercom?.('shutdown')
}

export const logEventThrottled = throttle(logEvent, 1000, { leading: false })
