import type { TPaymentsGroup } from 'lib/navigation/hooks/usePaymentsGroupQueryParam/usePaymentsGroupQueryParam'
import type { IReconciliationItemsFilters } from 'lib/navigation/interfaces'
import { ERouterPage } from 'lib/navigation/consts'
import { getURLSearch, TQueryParams } from 'lib/navigation/helpers/getURLSearch/getURLSearch'

export interface IGetPaymentsPathParams {
  businessId: Maybe<string>
  params?: TQueryParams
  group?: Maybe<TPaymentsGroup>
  filters?: IReconciliationItemsFilters
}

/**
 * Returns path to the my payments page with an optional search query.
 */
export const getPaymentsPath = ({ businessId, group, params, filters }: IGetPaymentsPathParams) => {
  let path = `/${businessId}${ERouterPage.payments}`

  const searchParams = getURLSearch({ tab: group, ...params, ...filters })

  if (searchParams) {
    path += `?${searchParams}`
  }

  return path
}
