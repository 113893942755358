import { useGroupQueryParam } from '../useGroupQueryParam/useGroupQueryParam'

const RECEIVABLE_GROUPS = ['unscheduled', 'scheduled', 'paid', 'trash', 'draft'] as const

export type TReceivableGroup = ArrayMember<typeof RECEIVABLE_GROUPS>

/**
 * Returns active receivable group router parameter.
 * Returns null when it's missing.
 */
export const useReceivableGroupQueryParam = () => useGroupQueryParam({ groups: RECEIVABLE_GROUPS })
