import { ISettingsQueryParams, DEFAULT_SETTINGS_TAB } from 'lib/navigation'
import { ERouterPage } from 'lib/navigation/consts'

interface IOptions extends Pick<ISettingsQueryParams, 'bankAccountId' | 'ruleId' | 'tab' | 'teamMemberId' | 'roleId'> {
  scrollTo?: 'two-way-sync'
  error?: string
}

/**
 * Returns the base path to the settings dialog
 * without any particular page.
 */
export const getSettingsPath = (
  businessId: Maybe<string>,
  { bankAccountId, ruleId, tab, teamMemberId, roleId, scrollTo, error }: IOptions = { tab: DEFAULT_SETTINGS_TAB },
) => {
  let path = (businessId ? `/${businessId}` : ERouterPage.root) + `?settings=${tab}`

  if (bankAccountId) {
    path += `&bank_account_id=${bankAccountId}`
  }

  if (teamMemberId) {
    path += `&team_member_id=${teamMemberId}`
  }

  if (ruleId) {
    path += `&rule_id=${ruleId}`
  }

  if (roleId) {
    path += `&role_id=${roleId}`
  }

  if (scrollTo) {
    path += `&scrollTo=${scrollTo}`
  }

  if (error) {
    path += `&error=${error}`
  }

  return path
}
