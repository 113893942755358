import { useGroupQueryParam, TGroupQueryParamHookParams } from '../useGroupQueryParam/useGroupQueryParam'

const PAYABLE_GROUPS = ['unscheduled', 'scheduled', 'paid', 'trash', 'in_review', 'approved', 'rejected'] as const

export type TPayableGroup = ArrayMember<typeof PAYABLE_GROUPS>

/**
 * Custom hook to handle the payable group/tab query parameter for navigation.
 *
 * @param query - The optional query parameter value for payable group/tab.
 * @returns The value of payable group/tab or null.
 */
export const usePayableGroupQueryParam = (query?: TGroupQueryParamHookParams['query']) =>
  useGroupQueryParam({ groups: PAYABLE_GROUPS, query })
