import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { getDefaultPaginationPerPage, TPaginationPerPageTableKey } from 'lib/pagination'

export const usePaginationParams = (tableKey: TPaginationPerPageTableKey = 'payables') => {
  const { query } = useRouter()
  const page = Number(query.page) || 1
  const perPage = Number(query.per_page) || getDefaultPaginationPerPage(tableKey)

  return useMemo(() => ({ page, perPage }), [page, perPage])
}
