import { useCallback } from 'react'
import { useBusinessIdPathParam, getPurchaseOrdersPath } from 'lib/navigation'
import { IPurchaseOrderQueryParams } from 'lib/navigation/hooks/usePurchaseOrderNavigation/usePurchaseOrderNavigation'

export const useOpenPurchaseOrderInNewTab = () => {
  const businessId = useBusinessIdPathParam()

  return useCallback(
    ({ purchaseOrderId }: IPurchaseOrderQueryParams) => {
      const baseUrl = window.location.origin
      const path = getPurchaseOrdersPath({ businessId, params: { purchase_order_id: purchaseOrderId } })

      window.open(new URL(path, baseUrl).toString(), '_blank')
    },
    [businessId],
  )
}
