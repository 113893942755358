import { useGroupQueryParam } from '../useGroupQueryParam/useGroupQueryParam'

const CATALOG_TABS = ['archived'] as const

export type TCatalogTab = ArrayMember<typeof CATALOG_TABS>

/**
 * Returns active catalog group router parameter or `null` when it's missing.
 */
export const useCatalogTabQueryParam = () => useGroupQueryParam({ groups: CATALOG_TABS })
