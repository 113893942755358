import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { ERouterPage } from 'lib/navigation'

interface IPublicPurchaseOrderQueryParams {
  email?: string
}

type TOpen = (params?: IPublicPurchaseOrderQueryParams) => void
type TUsePublicPurchaseOrderNavigation = () => {
  open: TOpen
}

export const usePublicPurchaseOrderNavigation: TUsePublicPurchaseOrderNavigation = () => {
  const open: TOpen = useCallback(
    ({ email } = {}) => Router.push({ query: { ...Router.query, email }, pathname: ERouterPage.publicPurchaseOrder }),
    [],
  )

  return useMemo(() => ({ open }), [open])
}
