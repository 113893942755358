import { TPayableGroup } from 'lib/navigation/hooks'
import { IPayableInvoicesFilters } from 'lib/navigation/interfaces'
import { getURLSearch, TQueryParams } from 'lib/navigation/helpers/getURLSearch/getURLSearch'
import { ERouterPage } from 'lib/navigation/consts'

export interface IGetPayablesPathParams {
  businessId: Maybe<string>
  params?: TQueryParams
  group?: Maybe<TPayableGroup>
  filters?: IPayableInvoicesFilters
}

/**
 * Returns path to the payables page with optional search query param.
 */
export const getPayablesPath = ({ businessId, params, group, filters }: IGetPayablesPathParams) => {
  let path = `/${businessId}${ERouterPage.payables}`

  const searchParams = getURLSearch({ tab: group, ...params, ...filters })

  if (searchParams) {
    path += `?${searchParams}`
  }

  return path
}
