export interface IGetReceivableInvoicePathParams {
  invoiceId: Maybe<string>
}

/**
 * Returns the base path to the receivable invoice dialog
 * without any particular page.
 */
export const getReceivableInvoicePath = (businessId: Maybe<string>, { invoiceId }: IGetReceivableInvoicePathParams) =>
  `/${businessId}?receivable_id=${invoiceId}`
