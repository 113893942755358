export interface IGetCatalogPathParams {
  businessId: Maybe<string>
  search?: string
}

/**
 * Returns path to the catalog page.
 */
export const getCatalogPath = ({ businessId, search }: IGetCatalogPathParams) => {
  let path = `/${businessId}/catalog`

  if (search) {
    path += `?${search}`
  }

  return path
}
