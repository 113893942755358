import { useMemo } from 'react'
import {
  IPurchaseOrdersQueryParams,
  usePurchaseOrdersTabQueryParam,
  usePurchaseOrdersSearchQueryParams,
  usePurchaseOrdersSortingQueryParams,
} from 'lib/navigation'
import { usePaginationParams } from 'lib/hooks/usePaginationParams/usePaginationParams'

export const usePurchaseOrdersNavigationQueryParams = (): IPurchaseOrdersQueryParams => {
  const tab = usePurchaseOrdersTabQueryParam()
  const sortingParams = usePurchaseOrdersSortingQueryParams()
  const paginationParams = usePaginationParams()
  const searchQueryParams = usePurchaseOrdersSearchQueryParams()

  return useMemo(
    () => ({
      tab,
      ...searchQueryParams,
      ...paginationParams,
      ...sortingParams,
    }),
    [tab, sortingParams, paginationParams, searchQueryParams],
  )
}
