import type { ParsedUrlQuery } from 'querystring'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { z } from 'zod'

export type TGroupQueryParamHookParams<T extends string = string> = {
  groups: readonly [T, ...T[]]
  query?: ParsedUrlQuery
}

/**
 * Returns an active group router parameter; returns null if it's missing.
 *
 * @template T - The type of the group query parameter.
 * @param {TGroupQueryParamHookParams<T>} options - The options for the hook.
 * @param {T[]} options.groups - The array of valid group values.
 * @param {Record<string, string | string[]>} options.query - The query object from the router.
 * @returns {T | null} - The value of the group query parameter or null if it is invalid.
 */
export const useGroupQueryParam = <T extends string = string>({
  groups,
  query,
}: TGroupQueryParamHookParams<T>): T | null => {
  const { query: { tab, group } = {} } = useRouter()

  const param = query ? query.tab ?? query.group : tab ?? group

  return useMemo(() => {
    const qParse = z.enum(groups).safeParse(param)

    return qParse.success ? qParse.data : null
  }, [groups, param])
}
