import { ERouterPage } from 'lib/navigation/consts'

/** Returns path to the custom error page. */
export const getErrorPath = ({
  message,
  reloadPath,
}: {
  message?: Maybe<string>
  reloadPath?: Maybe<string>
} = {}) => {
  let errorPath: string = ERouterPage.error

  if (message) {
    errorPath += `?message=${message}`
  }

  if (reloadPath) {
    errorPath += `${errorPath.includes('?') ? '&' : '?'}reloadPath=${reloadPath}`
  }

  return errorPath
}
