import type { ParsedUrlQueryInput } from 'querystring'
// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { pickBy, isNil, size, omit, isEmpty } from 'lodash'
import {
  getPayablesPath,
  useBusinessIdPathParam,
  usePayableGroupQueryParam,
  IGetPayablesPathParams,
  TPayableGroup,
  IPayableInvoicesFilters,
} from 'lib/navigation'
import { IPaginationQueryParams } from 'lib/pagination'
import { InvoiceSortableFieldEnum, InvoiceSortingDirectionEnum } from 'gql/types'

export interface IPayableInvoicesQueryParams extends IPaginationQueryParams {
  fees?: boolean
  vendorNameSearch?: string
  group?: Maybe<TPayableGroup>
  invoiceNumberSearch?: string
  purchaseOrderNumberSearch?: string
  labelIds?: string[]
  sortingField?: InvoiceSortableFieldEnum
  sortingDirection?: InvoiceSortingDirectionEnum
  filters?: IPayableInvoicesFilters
  openSearch?: boolean
  invoiceAmountSearch?: string
  confirmationNumberSearch?: string
}

type TGetQueryParams = (params?: IPayableInvoicesQueryParams) => ParsedUrlQueryInput
type TOpen = (
  params?: IPayableInvoicesQueryParams,
  options?: Parameters<(typeof Router)['push']>[2],
) => Promise<boolean>

type TGetPathConfig = { keptParams?: string[] | 'all' }
type TGetPath = (params?: Partial<IGetPayablesPathParams>, config?: TGetPathConfig) => string

type TUseReceivableInvoicesNavigation = () => {
  open: TOpen
  getPath: TGetPath
  getQueryParams: TGetQueryParams
}

/** Handles payable invoices page navigation. */
export const usePayablesNavigation: TUseReceivableInvoicesNavigation = () => {
  const payableGroup = usePayableGroupQueryParam()
  const businessId = useBusinessIdPathParam()

  const getQueryParams: TGetQueryParams = useCallback(
    ({
      page,
      fees,
      perPage,
      filters,
      sortingField,
      vendorNameSearch,
      sortingDirection,
      invoiceNumberSearch,
      purchaseOrderNumberSearch,
      labelIds,
      group = payableGroup,
      openSearch,
      invoiceAmountSearch,
      confirmationNumberSearch,
    } = {}) => ({
      ...(group && { tab: group }),
      ...(page && { page }),
      ...(perPage && { per_page: perPage }),
      ...(typeof fees === 'boolean' && { fees }),
      ...(sortingField && { sort: sortingField.toLowerCase() }),
      ...(sortingDirection && { order: sortingDirection.toLowerCase() }),
      ...(invoiceNumberSearch && { invoice_number_search: invoiceNumberSearch }),
      ...(purchaseOrderNumberSearch && { purchase_order_number_search: purchaseOrderNumberSearch }),
      ...(vendorNameSearch && { vendor_name_search: vendorNameSearch }),
      ...(size(labelIds) > 0 && { label_ids: labelIds }),
      ...pickBy(filters, (value) => !isNil(value)),
      ...(typeof openSearch === 'boolean' && { openSearch }),
      ...(invoiceAmountSearch && { invoice_amount_search: invoiceAmountSearch }),
      ...(confirmationNumberSearch && { confirmation_number_search: confirmationNumberSearch }),
    }),
    [payableGroup],
  )

  const open: TOpen = useCallback(
    (params = {}, options = {}) =>
      Router.push({ pathname: getPayablesPath({ businessId }), query: getQueryParams(params) }, undefined, options),
    [businessId, getQueryParams],
  )

  const getPath: TGetPath = useCallback(
    (params = {}, { keptParams = [] } = {}) => {
      const searchParams = {
        ...(keptParams !== 'all' && size(keptParams) && pickBy(Router.query, (_, key) => keptParams.includes(key))),
        ...(keptParams === 'all' && omit(Router.query, 'businessId')),
        ...params?.params,
      }

      return getPayablesPath({
        ...params,
        params: isEmpty(searchParams) ? undefined : searchParams,
        businessId: params?.businessId ?? businessId,
      })
    },
    [businessId],
  )

  return useMemo(() => ({ open, getPath, getQueryParams }), [open, getPath, getQueryParams])
}
