import { IPublicInvoiceQueryParams } from 'lib/navigation/interfaces'

/**
 * Returns path to the public invoice page
 * with an optional search query.
 */
export const getPublicInvoicePath = ({ uuid, dialog }: IPublicInvoiceQueryParams) => {
  let path = `/public-invoice/${uuid}`

  if (dialog) {
    path += `?dialog=${dialog}`
  }

  return path
}
