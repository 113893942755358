import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import { getClientManagerTeamMembersPath, useBusinessIdPathParam } from 'lib/navigation'

interface IParams {
  businessId?: string
}

/**
 * Handles client manager team members page navigation.
 */
export const useClientManagerTeamMembersNavigation = () => {
  const businessIdPathParam = useBusinessIdPathParam()

  const open = useCallback(
    ({ businessId }: IParams = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return Router.push({ pathname: getClientManagerTeamMembersPath(businessIdToUse) })
    },
    [businessIdPathParam],
  )

  const getPath = useCallback(
    ({ businessId, ...params }: IParams = {}) => {
      const businessIdToUse = businessId || businessIdPathParam

      return getClientManagerTeamMembersPath(businessIdToUse, params)
    },
    [businessIdPathParam],
  )

  return useMemo(() => ({ open, getPath }), [open, getPath])
}
