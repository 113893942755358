import { IPayableQueryParams } from 'lib/navigation/hooks/usePayableNavigation/usePayableNavigation'

export type TGetPayablePathParams = Pick<IPayableQueryParams, 'invoiceId'>
type TGetPayablePath = (businessId: Maybe<string>, params: TGetPayablePathParams) => string

/**
 * Returns the base path to the payable dialog
 * without any particular page.
 */
export const getPayablePath: TGetPayablePath = (businessId, { invoiceId }) => `/${businessId}?invoice_id=${invoiceId}`
