import pick from 'lodash/pick'
import Router from 'next/router'
import { useCallback, useMemo } from 'react'
import type { IPaginationQueryParams } from 'lib/pagination'
import { useBusinessIdPathParam, getCatalogPath, TCatalogTab } from 'lib/navigation'

export type TCatalogNavigationQueryParams =
  | 'tab'
  | 'page'
  | 'per_page'
  | 'vendor_name_search'
  | 'category_search'
  | 'label_ids'
  | 'search'

export interface ICatalogSearchQueryParams {
  vendorNameSearch?: string
  categorySearch?: string
  labelIds?: string[]
  search?: string
}

interface IOpenParams extends IPaginationQueryParams, ICatalogSearchQueryParams {
  tab?: Maybe<TCatalogTab>
}
interface IRouterPushConfig extends NonNullable<Parameters<(typeof Router)['push']>[2]> {
  keptParams?: TCatalogNavigationQueryParams[]
}

/** Handles catalog page navigation. */
export const useCatalogNavigation = () => {
  const businessId = useBusinessIdPathParam()

  const open = useCallback(
    (
      { page, perPage, tab, search, vendorNameSearch, categorySearch, labelIds }: IOpenParams,
      { keptParams = [], ...config }: IRouterPushConfig = {},
    ) => {
      const query: Partial<Record<TCatalogNavigationQueryParams, string | string[] | number>> = {
        ...pick(Router.query, keptParams),
        ...(tab && { tab }),
        ...(page && { page }),
        ...(perPage && { per_page: perPage }),
        ...(search && { search }),
        ...(vendorNameSearch && { vendor_name_search: vendorNameSearch }),
        ...(categorySearch && { category_search: categorySearch }),
        ...(labelIds && { label_ids: labelIds }),
      }

      return Router.push(
        {
          pathname: getCatalogPath({ businessId }),
          query,
        },
        undefined,
        config,
      )
    },
    [businessId],
  )

  const getPath = useCallback((search?: string) => getCatalogPath({ businessId, search }), [businessId])

  return useMemo(() => ({ open, getPath }) as const, [open, getPath])
}
