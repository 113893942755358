import type { TPaymentDialogParamValue } from '../getPaymentDialogParamValue/getPaymentDialogParamValue'

export enum EPaymentAutoFocusField {
  payFrom = 'pay_from',
  eptTerms = 'ept_terms',
  scheduleDate = 'schedule_date',
}

export type TGetPaymentPathParams = {
  extPeriod?: string
  autoFocus?: EPaymentAutoFocusField
  page?: string
  paymentDialog: TPaymentDialogParamValue
} & (
  | {
      transferId: string
      reconciliationItemId?: never
    }
  | {
      reconciliationItemId: string
      transferId?: never
    }
)

type TGetPaymentPath = (businessId: Maybe<string>, params: TGetPaymentPathParams) => string

/**
 * Returns the base path to the payment dialog
 * without any particular page.
 */
export const getPaymentPath: TGetPaymentPath = (
  businessId,
  { transferId, reconciliationItemId, extPeriod, page, paymentDialog, autoFocus },
) => {
  let path = ''

  if (businessId) {
    path = `/${businessId}`
  }

  if (page) {
    path += `${page.startsWith('/') ? '' : '/'}${page}`
  }

  path += `?payment_dialog=${paymentDialog}`

  if (transferId) {
    path += `&transfer_id=${transferId}`
  }

  if (reconciliationItemId) {
    path += `&reconciliation_item_id=${reconciliationItemId}`
  }

  if (extPeriod) {
    path += `&ext_period=${extPeriod}`
  }

  if (autoFocus) {
    path += `&auto_focus=${autoFocus}`
  }

  return path
}
