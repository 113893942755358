import { forwardRef } from 'react'
import { ExtendButtonBase, ButtonBaseTypeMap, CircularProgress } from '@mui/material'
import * as $ from './IconButton.styled'
import { IIconButtonProps, TIconButtonProps } from './IconButton.types'

// eslint-disable-next-line react/display-name
export const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(
  ({ size = 'medium', kind = 'neutral', variant = 'tertiary', sx, loading, children, ...otherProps }, ref) => (
    <$.IconButton sx={sx} kind={kind} variant={variant} size={size} ref={ref} {...otherProps}>
      {loading ? <CircularProgress size={20} color="inherit" /> : children}
    </$.IconButton>
  ),
) as ExtendButtonBase<ButtonBaseTypeMap<IIconButtonProps>>
