import type { ParsedUrlQuery } from 'querystring'
import { getLSItem } from 'lib/helpers/getLSItem/getLSItem'

export const PAGINATION_PER_PAGE_DEFAULT = 25
export const PAGINATION_PER_PAGE_OPTIONS = [25, 50, 75, 100]
export type TPaginationPerPageTableKey = 'payables' | 'payments' | 'receivables' | 'client-status-clients'

export const getDefaultPaginationPerPage = (tableKey?: TPaginationPerPageTableKey) => {
  if (!tableKey) {
    return PAGINATION_PER_PAGE_DEFAULT
  }
  const savedPerPage = getLSItem(`${tableKey}_countPerPage`)

  return savedPerPage ? Number(savedPerPage) : PAGINATION_PER_PAGE_DEFAULT
}

export const setDefaultPaginationPerPage = (tableKey: TPaginationPerPageTableKey, perPage: number) => {
  localStorage.setItem(`${tableKey}_countPerPage`, String(perPage))
}

export interface IPaginationQueryParams {
  perPage?: number
  page?: number
}

export const parseLocalBusinessesScreenPaginationParams = (
  query: ParsedUrlQuery,
): Required<IPaginationQueryParams> => ({
  page: Number(query.page) || 1,
  perPage: Number(query.per_page) || 50,
})
